import React from "react";
import Stack from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import Link from "next/link";
import logo from "../../public/images/new-logo.png";
import logoWhite from "../../public/images/logo_white.svg";
import darkTheme from '../../src/theme';
import ListItem from '@mui/material/ListItem'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const mobileNavContainer = {    
  width: '100%',
  zIndex: 2,
  background: darkTheme.palette.common.black,
  padding: darkTheme.spacing(2),
  paddingBottom: darkTheme.spacing(10)
};
      
const logoContainer = {
  padding: 1,
  display: 'flex',
  alignSelf: 'center',
};

const bottomNavLinks = {
  '& ul': {
    display: 'block',
    padding: 0,
    '& li': {
      display: 'inline',
      wordBreak: 'break-all',
      '& a': {
        fontSize: 12,
        color: darkTheme.palette.grey[400],
      }
    }
  }
};
export default function MobileFooter() {
  return (
      <Typography sx={mobileNavContainer}>
        <Typography component="div">
            <Link href="/">
              <a>
                <Image src={logo} alt="..." width={80} height={28} sx={logoContainer} />
              </a>
            </Link>
          <Typography component="div" sx={bottomNavLinks}>
            <ul>
              <li>
                <Link href={"/about"} passHref>
                  <a>About Us&nbsp;&nbsp;|&nbsp;&nbsp;</a>
                </Link>
              </li>
              <li>
                <Link href={"/contact"} passHref>
                  <a>Contact Us&nbsp;&nbsp;|&nbsp;&nbsp;</a>
                </Link>
              </li>
              <li>
                <Link href={"/mag"} passHref>
                  <a>Clubr Mag&nbsp;&nbsp;|&nbsp;&nbsp;</a>
                </Link>
              </li>
              <li>
                <Link href={"/terms"} passHref>
                  <a>Terms of Use&nbsp;&nbsp;|&nbsp;&nbsp;</a>
                </Link>
              </li>
              <li>
                <Link href={"/refund"} passHref>
                  <a>Refund Policy</a>
                </Link>
              </li>
            </ul>
          </Typography>
          <Typography component="hr" sx={{borderColor:`${darkTheme.palette.grey[900]} !important`, height:1, width:'100%'}} />
          <Grid container spacing={0} sx={{py:darkTheme.spacing(2)}}>
          <Grid item md={4} sm={12} xs={12}>
              <Stack
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
              >                              
                  <ListItem>
                      <Link href="https://www.facebook.com/Clubrapp" rel="noreferrer" passHref><a target="_blank" style={{color:darkTheme.palette.grey[200]}}><FacebookIcon /></a></Link>
                  </ListItem>
                  <ListItem>
                      <Link href="https://twitter.com/Clubr14" rel="noreferrer" passHref><a target="_blank" style={{color:darkTheme.palette.grey[200]}}><TwitterIcon /></a></Link>
                  </ListItem>
                  <ListItem>
                      <Link href="https://www.instagram.com/clubr.in/" rel="noreferrer" passHref><a target="_blank" style={{color:darkTheme.palette.grey[200]}}><InstagramIcon /></a></Link>
                  </ListItem>
                  <ListItem>
                      <Link href="https://www.linkedin.com/company/clubrapp" rel="noreferrer" passHref><a target="_blank" style={{color:darkTheme.palette.grey[200]}}><LinkedInIcon /></a></Link>
                  </ListItem>                            
              </Stack> 
              </Grid>
              {/* <Grid item md={6} sm={6} xs={6}> 
                <Stack spacing="0" direction="row" alignItems="center" justifyContent="flex-end" display="flex">
                  <Typography
                    sx={{
                      color: darkTheme.palette.common.white,
                      fontSize: 12,
                      textAlign: 'right',                      
                    }}
                  >
                    Powered by
                  </Typography>
                  <Image src={logoWhite} alt="..." width={35} height={25} /> 
              </Stack>
            </Grid> */}
          </Grid>
        </Typography>
      </Typography>
  );
}
